import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'


export default function CourseMaterial() {
  const [list, setList] = useState([])
  let navigate = useNavigate()


  useEffect(() => {
    axios.get('http://192.168.2.30:8800/link').then((response) => {
      setList(response.data)
    })
  }, [])


  return (
    <div className='w-full h-full md:h-screen'>
      <h1 className='pt-28 pb-5 text-4xl flex justify-center font-sans'>
        Конспекты курса
      </h1>
        <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/11cM2nyWs8Ph0pLrIv_Qz9eQPTk3Mb4z5/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>WhatsApp (Ватсап)</p>
        </div>
        </a>
      </div>
       <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1kvtBwX8bJ_CUUigy4fFmlwm8qoXSKPEv/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Электронная почта</p>
        </div>
        </a>
      </div>
	<div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1fYpMtYN6N0ha8qoOqAjLivQh5zbnQB5E/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Облачное хранилище</p>
        </div>
        </a>
      </div>
      <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1mWuM5BhgiGP5ek-rCN0SGq9-kTlAYFB_/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Zoom (Зум)</p>
        </div>
        </a>
      </div>
      <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1TPfEAwJE4yPEGnOIAHzS6OO0OexFnA-y/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Безопасность в интернете</p>
        </div>
        </a>
      </div>
      <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1NOW9u4qK5XTMZXwSAvcrngIX2ySF_BJT/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Яндекс такси.переводчик.букмейт</p>
        </div>
        </a>
      </div>
       <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1GgdufZ19zZig6yyfmr74tu5LFa892xcY/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Wildberries (Вайлдберриз)</p>
        </div>
        </a>
      </div>
     <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1lv1qsKHWJQYAxnEXIU7QGNxGlcs7lCbK/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Покупка авиабилетов</p>
        </div>
        </a>
      </div> 
      <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/13y3msswVXNFad4R-qO6pdaIUOJBNy8vW/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Покупка ЖД билетов</p>
        </div>
        </a>
      </div> 
	<div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1CQo7m9x3p3Yq5uVikUXW3G6QZw8JpRKA/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>YouTube (Ютуб)</p>
        </div>
        </a>
      </div>
       <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1ZdwzZf0qll8-0rkFkVtJ5MbTqakRyEpJ/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Почта России</p>
        </div>
        </a>
      </div>
	<div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/11w0Bv7MvTlxt7e54yEJ1bazTkNANq85j/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Госуслуги</p>
        </div>
        </a>
      </div>
      <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/13f-qO4_QvCgZpYTCPyThUX_qz1iliDXP/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Rutube (Рутуб)</p>
        </div>
        </a>
      </div>
      <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1QV_gGtWPvax1g4oNpudXVUTJEyqYI4Qv/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Сбербанк онлайн</p>
        </div>
        </a>
      </div>
      <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1-iUQQP7cHKqL9IHJHi2HAEVUwMsEO98w/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Создание презентации</p>
        </div>
        </a>
      </div>
     <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/10wG6lznVPvfR4oMMvqp3bw-CZEWhNwCQ/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>2ГИС</p>
        </div>
        </a>
      </div> 
     <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1vec61rnqTuyKyE8HiwufaRPsE1wZ9lsa/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>BusTime (Бастайм)</p>
        </div>
        </a>
      </div>
     <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1C1B7cetZ3dLnvEyJ7ofQ2bfL1ZtTFXzh/view">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>ВКонтакте</p>
        </div>
        </a>
      </div>
      <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/1CDsAllcCrnYdTIgFnIXfYWi4lZR0qLxp/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Создание текстовых документов</p>
        </div>
        </a>
      </div>
      <div className='px-5 my-4 md:px-12'>
        <a href="https://drive.google.com/file/d/19dKFUEsoJNNt2i9cAUzm3x_NEtyPVRJ6/preview">
        <div className='bg-slate-400 bg-opacity-30 w-full py-3 rounded-md cursor-pointer'>
          <p className='truncate text-3xl px-5'>Ozon (Озон)</p>
        </div>
        </a>
      </div>>
    </div>
  )
}
